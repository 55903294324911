import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import NavigationLink from "./NavigationLink"
import {device} from "../helpers/mediaQueries"

const NavigationLinks = ({currentPath, isFooter}) => {
  const paths = [
    {
      label: "Home",
      to: "/",
    },
    {
      label: "About",
      to: "/about/",
    },
    {
      label: "Contact",
      to: "/contact/",
    },
  ]

  return (
    <Nav>
      <ul>
        {paths.map((path) => (
          <NavigationLink
            isCurrent={currentPath === path.to}
            isFooter={isFooter}
            key={path.label}
            to={path.to}
          >
            {path.label}
          </NavigationLink>
        ))}
      </ul>
    </Nav>
  )
}

const Nav = styled.nav`
  color: white;
  flex-grow: 1;
  a {
    color: inherit;
    display: block;
  }
  > ul {
    display: flex;
    justify-content: center;
    > li:not(:first-child) {
      margin-left: 2em;
    }
  }
  @media ${device.desktop} {
    > ul {
      justify-content: flex-end;
    }
  }
`

NavigationLinks.propTypes = {
  currentPath: PropTypes.string.isRequired,
  isFooter: PropTypes.bool,
}

NavigationLinks.defaultProps = {
  isFooter: false,
}

export default NavigationLinks
