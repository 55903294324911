import React from "react"

const CaratIcon = (props) => {
  return (
    <svg viewBox="0 0 50 50">
      <path
        d="M20,41.7L1.2,15.4c-3-4.1,0-9.7,5-9.7h37.6c5,0,7.9,5.7,5,9.7L30.1,41.7C27.6,45.2,22.4,45.2,20,41.7z"
        fill={props.fill}
      />
    </svg>
  )
}

CaratIcon.defaultProps = {
  fill: "#000",
}

export {CaratIcon}
