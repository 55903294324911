import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Boundary from "./Boundary"
import Navigation from "./Navigation"
import {device} from "../helpers/mediaQueries"
import Flex from "./Flex"

const Footer = ({currentPath}) => {
  return (
    <footer>
      <TopDiv>
        <Navigation currentPath={currentPath} isFooter />
      </TopDiv>
      <BottomDiv>
        <Boundary>
          <Flex justifyContent="center" isMobile={false}>
            <BottomItemDiv>
              <strong>© 2020</strong> Assalam Center School
            </BottomItemDiv>
            <BottomItemDiv>All Rights Reserved</BottomItemDiv>
          </Flex>
        </Boundary>
      </BottomDiv>
    </footer>
  )
}

const BottomDiv = styled.div`
  background-color: #1a1f2f;
  color: #6c81a0;
  font-size: ${(props) => props.theme.fontSizeSmaller}px;
  letter-spacing: 0.05em;
  padding: 1.25em 0;
  text-align: center;
`

const BottomItemDiv = styled.div`
  @media ${device.desktop} {
    margin: 0 10px;
  }
`

const TopDiv = styled.div`
  background-image: linear-gradient(135deg, #5e718e, #2d3b50);
  padding: 20px;
  @media ${device.desktop} {
    padding: 0;
  }
`

Footer.propTypes = {
  currentPath: PropTypes.string.isRequired,
}

export default Footer
