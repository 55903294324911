import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import {device} from "../helpers/mediaQueries"
import Boundary from "./Boundary"
import Flex from "./Flex"
import {Logo} from "./Logo"
import NavigationLinks from "./NavigationLinks"

const Navigation = ({currentPath, isFooter}) => {
  return (
    <Boundary>
      <OuterFlex isMobile={false}>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        <NavigationLinks currentPath={currentPath} isFooter={isFooter} />
      </OuterFlex>
    </Boundary>
  )
}

const OuterFlex = styled(Flex)`
  height: ${(props) => props.theme.navigationDesktopHeight}px;
`

const LogoLink = styled(Link)`
  display: inline-block;
  margin-bottom: 20px;
  line-height: 1;
  transform: translateX(-4%);
  @media ${device.desktop} {
    margin-bottom: 0;
  }
`

Navigation.propTypes = {
  currentPath: PropTypes.string.isRequired,
  isFooter: PropTypes.bool,
}

Navigation.defaultProps = {
  isFooter: false,
}

export default Navigation
