import Types from "prop-types"
import React from "react"
import styled from "styled-components"
import {device} from "../helpers/mediaQueries"

const Heading = ({children, ...rest}) => {
  return (
    <OuterDiv>
      <HeadingType {...rest}>{children}</HeadingType>
    </OuterDiv>
  )
}

function prepareType({children, type, ...rest}) {
  switch (type) {
    case "1":
      return <h1 {...rest}>{children}</h1>
    case "2":
      return <h2 {...rest}>{children}</h2>
    case "4":
      return <h4 {...rest}>{children}</h4>
    case "5":
      return <h5 {...rest}>{children}</h5>
    case "6":
      return <h6 {...rest}>{children}</h6>
    default:
      return <h3 {...rest}>{children}</h3>
  }
}

const HeadingType = styled(prepareType)`
  color: inherit;
  text-align: ${(props) => props.align};
`

const OuterDiv = styled.div`
  h1 {
    font-size: 40px;
  }
  @media ${device.desktop} {
    h1 {
      font-size: 60px;
    }
  }
`

Heading.propTypes = {
  align: Types.oneOf(["center", "left", "right"]),
  type: Types.oneOf(["1", "2", "3", "4", "5", "6"]),
}

Heading.defaultProps = {
  align: "center",
  type: "3",
}

export {Heading}
