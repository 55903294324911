import styled from "styled-components"
import {device} from "../helpers/mediaQueries"

const Boundary = styled.div`
  @media ${device.desktop} {
    margin: 0 auto;
    padding: 0;
    width: ${(props) => props.theme.boundaryDesktopWidth}px;
  }
`

export default Boundary
