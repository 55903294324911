import Types from "prop-types"
import React from "react"
import Helmet from "react-helmet"
import {createGlobalStyle} from "styled-components"
import avenirBoldWOFF from "../fonts/avenir-bold.woff"
import avenirBoldWOFF2 from "../fonts/avenir-bold.woff2"
import avenirRegularWOFF from "../fonts/avenir-regular.woff"
import avenirRegularWOFF2 from "../fonts/avenir-regular.woff2"
import Footer from "./Footer"
import Header from "./Header"

const Page = ({children, navigation}) => {
  const currentPath =
    typeof window !== "undefined" ? window.location.pathname : ""

  return (
    <>
      <Helmet>
        <title>Assalam Center of Boca Raton</title>
        <meta
          name="description"
          content="We are an Islamic school in Boca Raton, FL. We offer Islamic studies, Jummah prayer, and iftar during Ramadan."
        />
      </Helmet>
      <GlobalStyle />
      {navigation && <Header currentPath={currentPath} />}
      <main>{children}</main>
      <Footer currentPath={currentPath} />
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Avenir Next LT Pro';
    src: url(${avenirBoldWOFF2}) format('woff2'),
      url(${avenirBoldWOFF}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Avenir Next LT Pro';
    src: url(${avenirRegularWOFF2}) format('woff2'),
      url(${avenirRegularWOFF}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  // Groups
  
  button,
  input,
  textarea {
    font-family: inherit;
  }
  
  h1, h2, h3, h4, h5, h6, strong {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  
  input,
  textarea {
    background-color: ${(props) => props.theme.fieldBackgroundColor};
    border: 1px solid transparent;
    color: ${(props) => props.theme.textColor};
    font-family: inherit;
    font-size: ${(props) => props.theme.fontSizeMedium}px;
    padding: 0.75em;
    &:not(:disabled) {
      &:hover,
      &:focus {
        border-color: rgba(0, 0, 0, 0.375);
        outline: none;
      }
    }
    &:disabled {
      color: ${(props) => props.theme.textColorLight};
    }
  }
  
  // Pseudo
  
  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
  
  // Single
  
  * {
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
  }
  
  body {
    background-color: white;
    color: ${(props) => props.theme.textColor};
    font-family: "Avenir Next LT Pro", "Helvetica Neue", "Lato", sans-serif;
    font-feature-settings: "kern", "liga", "clig", "calt";
    font-kerning: normal;
    font-size: ${(props) => props.theme.fontSizeMedium}px;
    line-height: 1.625;
    margin: 0;
    text-align: center;
    word-wrap: break-word;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  }
  
  button {
    background-color: transparent;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: black;
    line-height: 1.25;
    font-size: ${(props) => props.theme.fontSizeMedium}px;
    margin: 0;
    text-rendering: optimizeLegibility;
  }
  
  h1 {
    font-size: 60px;
  }
  
  h2 {
    font-size: 50px;
  }
  
  h3 {
    font-size: 40px;
  }
  
  h4 {
    font-size: ${(props) => props.theme.fontSizeLarge}px;
  }
  
  hr {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: ${(props) => props.theme.gutterDesktop}px 0;
  }
  
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  
  p {
    margin-block-end: 2.5em;
    margin-block-start: 2em;
  }
  
  section {
    min-height: 100vh;
    position: relative;
  }
  
  svg {
    vertical-align: top;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`

Page.propTypes = {
  children: Types.node.isRequired,
  navigation: Types.bool,
}

Page.defaultProps = {
  navigation: true,
}

export default Page
