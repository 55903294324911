import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import {CaratIcon} from "./Icon.Carat"

const NavigationLink = ({children, isCurrent, isFooter, menuItems, to}) => {
  const [hoverRef, isHovered] = useHover()
  const hasMenuItems = menuItems.length > 0

  return (
    <LinkLi isCurrent={isCurrent} isHovered={isHovered} ref={hoverRef}>
      <Link to={to}>{children}</Link>
      {menuItems && (
        <>
          <CaratDiv hasMenuItems={hasMenuItems}>
            <CaratIcon fill="#000" />
          </CaratDiv>
          {hasMenuItems && (
            <MenuOuterDiv isFooter={isFooter} isHovered={isHovered}>
              <MenuInnerDiv>
                <ul>
                  {menuItems.map((menuItem) => (
                    <MenuLi key={menuItem.to}>
                      <Link to={menuItem.to}>
                        <MenuLiContent>{menuItem.label}</MenuLiContent>
                      </Link>
                    </MenuLi>
                  ))}
                </ul>
              </MenuInnerDiv>
            </MenuOuterDiv>
          )}
        </>
      )}
    </LinkLi>
  )
}

function useHover() {
  const [value, setValue] = React.useState(false)

  const ref = React.useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  React.useEffect(() => {
    const node = ref.current

    if (node) {
      node.addEventListener("mouseover", handleMouseOver)
      node.addEventListener("mouseout", handleMouseOut)

      return () => {
        node.removeEventListener("mouseover", handleMouseOver)
        node.removeEventListener("mouseout", handleMouseOut)
      }
    }
  })

  return [ref, value]
}

const CaratDiv = styled.div`
  display: ${(props) => !props.hasMenuItems && "none"};
  height: 0.4em;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.4em;
`

const LinkLi = styled.li`
  border-bottom: 2px solid
    ${(props) =>
      props.isCurrent ? "rgba(255, 255, 255, 0.25)" : "transparent"};
  position: relative;
  a {
    cursor: ${(props) => props.isCurrent && "default"};
  }
  &:not(:last-child) {
    margin-right: 0.7em;
  }
  &:hover {
    border-bottom: 2px solid rgba(255, 255, 255, 0.25);
  }
`

const MenuInnerDiv = styled.div`
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.25);
`

const MenuOuterDiv = styled.div`
  display: ${(props) => !props.isHovered && "none"};
  bottom: ${(props) => props.isFooter && "100%"};
  left: 50%;
  padding: 0.5em;
  position: absolute;
  top: ${(props) => !props.isFooter && "100%"};
  transform: translateX(-50%);
  z-index: 1;
`

const MenuLi = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: ${(props) => props.theme.fontSizeSmall}px;
  white-space: nowrap;
`

const MenuLiContent = styled.div`
  padding: 0.5em 1.5em;
`

NavigationLink.propTypes = {
  children: PropTypes.node.isRequired,
  isCurrent: PropTypes.bool,
  isFooter: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  to: PropTypes.string.isRequired,
}

NavigationLink.defaultProps = {
  isCurrent: false,
  isFooter: false,
  menuItems: [],
}

export default NavigationLink
