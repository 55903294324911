import React from "react"
import styled from "styled-components"

const Logo = () => {
  return (
    <OuterDiv>
      <svg viewBox="0 0 2201.68 400">
        <g>
          <g>
            <circle className="st1" cx="1196.3" cy="200" r="185.42" />
            <path
              className="st2"
              d="M1196.29,22.58c97.99,0,177.42,79.44,177.42,177.42s-79.44,177.42-177.42,177.42
			c-97.99,0-177.42-79.44-177.42-177.42S1098.31,22.58,1196.29,22.58 M1196.29,6.58c-26.1,0-51.44,5.12-75.29,15.21
			c-23.03,9.74-43.72,23.69-61.48,41.45c-17.76,17.76-31.7,38.44-41.45,61.48c-10.09,23.86-15.21,49.19-15.21,75.29
			s5.12,51.44,15.21,75.29c9.74,23.03,23.69,43.72,41.45,61.48c17.76,17.76,38.44,31.7,61.48,41.45
			c23.86,10.09,49.19,15.21,75.29,15.21c26.11,0,51.44-5.12,75.29-15.21c23.03-9.74,43.72-23.69,61.48-41.45
			c17.76-17.76,31.7-38.44,41.45-61.48c10.09-23.86,15.21-49.19,15.21-75.29s-5.12-51.44-15.21-75.29
			c-9.74-23.03-23.69-43.72-41.45-61.48c-17.76-17.76-38.44-31.7-61.48-41.45C1247.73,11.69,1222.4,6.58,1196.29,6.58L1196.29,6.58z
			"
            />
          </g>
          <path
            className="st3"
            d="M1018.87,200c0,3.18,0.09,6.34,0.25,9.48c13.49,3.91,27.74,6.01,42.49,6.01
		c84.18,0,152.42-68.24,152.42-152.42c0-13.86-1.86-27.29-5.33-40.06c-4.1-0.28-8.24-0.44-12.41-0.44
		C1098.31,22.58,1018.87,102.01,1018.87,200z"
          />
          <path
            className="st4"
            d="M1289.58,227.12v-4.65l2.16-2.15c0.5-0.5,0.5-1.3,0-1.79l-2.16-2.15v-5.71c8.76,2.43,17.16-6.38,17.07-6.38
		c-29.96,5.29-19.05-20.42-19.05-20.42s-13.85,10.61-5.64,21.28c1.65,2.15,3.39,3.56,5.13,4.5v6.68l-2.2,2.19
		c-0.5,0.49-0.5,1.29,0,1.79l2.2,2.19v4.59c-37.38,0.48-74.37,24.36-77.94,71.87c-0.04,0.01-0.11,0.02-0.15,0.03
		c-10.98,3.1-12,6.89-7.77,9.25c3.87,2.17,7.05,6.18,7.56,6.84v61.89c67.97-4.73,125.38-47.73,150.84-107.61
		C1346.44,241.68,1318.17,227.55,1289.58,227.12z"
          />
          <path
            className="st4"
            d="M1169.7,258.74c0-0.02,0-0.15,0-0.15c-2.48-0.73-4.94-1.36-7.38-1.94v-32.66c5.33-1.79,4.08-8.36,4.08-8.36
		s3.95-1.21,3.21-5.41c-2.09-1.26-4.58-2.2-7.26-2.93c0-6.4,0-16.16,0-16.2c-3.09-1.1-6.14-1.97-9.17-2.74v-26.99
		c7.45-0.79,5.88-8.76,5.88-8.76s3.94-1.21,3.2-5.41c-2.72-1.65-6.14-2.77-9.77-3.55c-1.31-5.08-6.96-20.92-25.07-21.39v-3.19
		l1.73-1.73c0.41-0.39,0.41-1.03,0-1.43l-1.73-1.74v-3.35c4.2,1,8.14-3.13,8.1-3.13c-14.56,2.56-9.25-9.92-9.25-9.92
		s-6.73,5.15-2.75,10.34c0.61,0.8,1.26,1.37,1.91,1.82v4.21l-1.77,1.77c-0.39,0.39-0.39,1.03,0,1.43l1.77,1.76v3.21
		c-17.72,0.74-23.66,16.32-25.1,21.35c-3.59,0.78-6.99,1.89-9.69,3.52c-0.74,4.21,3.21,5.41,3.21,5.41s-1.58,7.95,5.81,8.75v27.01
		c-5.75,1.4-9.09,2.72-9.09,2.72v16.19c-2.68,0.73-5.18,1.68-7.27,2.94c-0.74,4.21,3.2,5.41,3.2,5.41s-1.25,6.51,4.02,8.33v32.68
		c-4.66,1.07-7.3,1.95-7.3,1.95v21.63h0.02v56.53c24.24,20.06,53.9,33.8,86.45,38.69v-95.22
		C1169.7,280.22,1169.7,259.62,1169.7,258.74z M1134.45,164.49c0,0,0.81-4.42,3.79-3.93c3.74,0.59,3.88,5.02,3.88,5.02l4.06,0.77
		l0.14,20.06l-16.17-1.87l-0.15-20.51L1134.45,164.49z M1106.67,166.35l4.05-0.77c0,0,0.14-4.43,3.88-5.02
		c2.99-0.49,3.8,3.93,3.8,3.93l4.45-0.45l-0.16,20.51l-16.16,1.87L1106.67,166.35z M1111.07,252.66l-14.42,1.88l0.13-20.06
		l3.62-0.78c0,0,0.13-4.42,3.47-5.02c2.65-0.48,3.37,3.94,3.37,3.94l3.98-0.46L1111.07,252.66z M1134.74,252.27l-16.17,0.02
		l0.21-20.66h3.8c0,0,0.1-4.24,3.85-4.24c4.07,0,4.16,4.25,4.16,4.25h4.16L1134.74,252.27z M1141.78,252.66l-0.14-20.51l3.96,0.46
		c0,0,0.72-4.42,3.38-3.94c3.34,0.6,3.47,5.02,3.47,5.02l3.61,0.78l0.13,20.06L1141.78,252.66z"
          />
        </g>
        <g>
          <path
            className="st2"
            d="M233.9,273.89h-23.32L202,234.51h-62.26l-21.12,39.38h-22l83.6-152.24h18.26L233.9,273.89z M198.92,216.25
		l-14.52-67.32l-35.86,67.32H198.92z"
          />
          <path
            className="st2"
            d="M326.29,193.81c-5.72-6.6-13.86-10.78-22.66-10.78c-9.02,0-18.48,4.18-18.48,14.52
		c0,16.5,45.76,11.66,45.76,41.14c0,23.54-21.12,37.62-43.78,37.62c-14.52,0-28.82-6.16-36.52-18.92l11.66-12.1
		c5.5,9.02,17.16,14.52,27.5,14.52c10.12,0,21.56-4.84,21.56-16.72c0-18.92-45.32-12.32-45.32-40.7c0-21.12,18.04-34.98,38.94-34.98
		c13.42,0,23.76,3.96,32.34,14.52L326.29,193.81z"
          />
          <path
            className="st2"
            d="M421.33,193.81c-5.72-6.6-13.86-10.78-22.66-10.78c-9.02,0-18.48,4.18-18.48,14.52
		c0,16.5,45.76,11.66,45.76,41.14c0,23.54-21.12,37.62-43.78,37.62c-14.52,0-28.82-6.16-36.52-18.92l11.66-12.1
		c5.5,9.02,17.16,14.52,27.5,14.52c10.12,0,21.56-4.84,21.56-16.72c0-18.92-45.32-12.32-45.32-40.7c0-21.12,18.04-34.98,38.94-34.98
		c13.42,0,23.76,3.96,32.34,14.52L421.33,193.81z"
          />
          <path
            className="st2"
            d="M549.59,266.63c0,4.62,0.66,7.26,0.66,7.26h-20.02c0,0-0.66-2.42-0.66-7.04c0-2.42,0.22-6.38,1.1-9.9
		c-9.46,11.66-23.54,19.36-38.72,19.36c-26.18,0-41.36-18.26-41.36-43.78c0-33,21.78-65.34,58.08-65.34
		c13.86,0,25.96,5.72,32.34,18.26l2.64-16.28h20.46l-14.08,89.32C550.03,258.49,549.59,262.23,549.59,266.63z M510.21,184.79
		c-23.76,0-38.28,22.44-38.28,44.44c0,16.5,9.46,28.82,26.62,28.82c23.98,0,38.28-22.66,38.28-44.66
		C536.83,196.89,527.59,184.79,510.21,184.79z"
          />
          <path
            className="st2"
            d="M609.87,266.63c0,4.62,0.66,7.26,0.66,7.26h-20.9c0,0-0.66-2.42-0.66-7.04c0-2.64,0.22-6.16,0.88-9.9
		l18.92-119.24c0.44-3.3,0.66-5.94,0.66-8.36c0-4.62-0.66-7.26-0.66-7.26h20.68c0,0,0.66,2.42,0.66,7.04
		c0,2.64-0.22,6.16-0.88,10.12l-18.92,119.24C609.87,261.35,609.87,264.21,609.87,266.63z"
          />
          <path
            className="st2"
            d="M745.17,266.63c0,4.62,0.66,7.26,0.66,7.26h-20.02c0,0-0.66-2.42-0.66-7.04c0-2.42,0.22-6.38,1.1-9.9
		c-9.46,11.66-23.54,19.36-38.72,19.36c-26.18,0-41.36-18.26-41.36-43.78c0-33,21.78-65.34,58.08-65.34
		c13.86,0,25.96,5.72,32.34,18.26l2.64-16.28h20.46l-14.08,89.32C745.61,258.49,745.17,262.23,745.17,266.63z M705.79,184.79
		c-23.76,0-38.28,22.44-38.28,44.44c0,16.5,9.46,28.82,26.62,28.82c23.98,0,38.28-22.66,38.28-44.66
		C732.41,196.89,723.17,184.79,705.79,184.79z"
          />
          <path
            className="st2"
            d="M929.31,266.63c0,4.62,0.66,7.26,0.66,7.26h-20.68c0,0-0.66-2.42-0.66-7.04c0-2.64,0.22-6.16,0.88-9.9l7.04-44
		c0.66-3.3,1.1-6.82,1.1-10.12c0-9.9-3.52-17.6-14.74-17.6c-17.82,0-26.84,19.14-29.26,34.32l-8.58,54.34h-20.68l9.68-60.94
		c0.44-3.3,0.88-6.82,0.88-10.34c0-9.9-3.52-17.38-14.52-17.38c-18.04,0-27.06,19.14-29.48,34.32l-8.58,54.34h-20.46l14.08-89.1
		c0.44-3.3,0.66-5.94,0.66-8.36c0-4.62-0.66-7.26-0.66-7.26h20.24c0,0,0.66,2.42,0.66,7.04c0,2.64-0.22,5.94-0.88,9.68h0.44
		c6.82-10.56,19.36-18.48,31.9-18.48c13.86,0,23.98,6.16,26.62,20.24c8.14-11.88,20.46-20.24,35.2-20.24
		c19.8,0,28.6,11.22,28.6,30.14c0,4.4-0.44,8.8-1.1,12.98l-7.7,47.96C929.53,261.35,929.31,264.21,929.31,266.63z"
          />
        </g>
        <g>
          <path
            className="st2"
            d="M1583,157.51c-7.48-12.32-25.74-18.48-39.38-18.48c-38.28,0-64.24,33.88-64.24,70.4
		c0,27.94,17.38,47.08,45.76,47.08c17.16,0,33.88-6.6,46.42-18.48l6.6,15.84c-15.62,15.18-35.42,22.66-56.98,22.66
		c-38.28,0-63.8-24.86-63.8-63.14c0-49.72,36.52-93.5,88.44-93.5c18.04,0,37.18,5.72,48.84,20.24L1583,157.51z"
          />
          <path
            className="st2"
            d="M1647.68,229.23c-7.26,0-15.62-0.66-24.86-1.98c0,0-0.22,1.76-0.22,3.96c0,17.38,10.34,28.16,27.72,28.16
		c13.42,0,24.42-5.06,35.64-12.1l6.6,12.54c-12.98,11.22-30.36,16.5-47.3,16.5c-26.62,0-43.34-16.94-43.34-43.56
		c0-35.2,28.82-65.56,66.44-65.56c21.34,0,35.64,11.66,35.64,28.16C1704,202.83,1695.64,229.23,1647.68,229.23z M1665.72,182.37
		c-19.58,0-34.98,12.76-40.26,29.92c5.28,0.88,11.22,1.32,17.38,1.32c20.24,0,41.58-5.06,41.58-18.26
		C1684.42,188.53,1677.6,182.37,1665.72,182.37z"
          />
          <path
            className="st2"
            d="M1814.88,266.63c0,4.62,0.66,7.26,0.66,7.26h-20.9c0,0-0.66-2.42-0.66-7.04c0-2.64,0.22-6.16,0.88-9.9
		l6.6-41.36c0.66-4.18,1.1-8.14,1.1-12.32c0-11.88-4.84-18.04-17.16-18.04c-20.02,0-28.38,18.92-31.24,36.08l-8.36,52.58h-20.46
		l14.08-89.1c0.44-3.3,0.66-5.94,0.66-8.36c0-4.62-0.66-7.26-0.66-7.26h20.46c0,0,0.66,2.42,0.66,7.04c0,2.64-0.22,5.94-0.88,9.68
		h0.22c7.7-11.22,20.24-18.48,33.88-18.48c20.46,0,30.14,10.12,30.14,30.36c0,4.62-0.44,9.46-1.32,14.3l-7.26,46.42
		C1814.88,261.35,1814.88,264.21,1814.88,266.63z"
          />
          <path
            className="st2"
            d="M1890.55,169.39h23.98l-2.64,17.38h-24.2c-2.64,16.72-8.8,42.02-8.8,59.4c0,7.48,1.32,11.66,9.24,11.66
		c3.74,0,8.8-0.88,12.1-2.42l-1.54,17.38c-5.72,2.2-12.98,3.52-19.14,3.52c-15.84,0-22-8.36-22-22.66c0-21.56,6.38-46.64,9.46-66.88
		h-14.74l2.86-17.38h14.74l2.42-15.4c0.44-3.3,0.66-5.94,0.66-8.36c0-4.62-0.66-7.26-0.66-7.26h20.68c0,0,0.66,2.42,0.66,7.04
		c0,2.64-0.22,6.16-0.88,10.12L1890.55,169.39z"
          />
          <path
            className="st2"
            d="M1968.65,229.23c-7.26,0-15.62-0.66-24.86-1.98c0,0-0.22,1.76-0.22,3.96c0,17.38,10.34,28.16,27.72,28.16
		c13.42,0,24.42-5.06,35.64-12.1l6.6,12.54c-12.98,11.22-30.36,16.5-47.3,16.5c-26.62,0-43.34-16.94-43.34-43.56
		c0-35.2,28.82-65.56,66.44-65.56c21.34,0,35.64,11.66,35.64,28.16C2024.97,202.83,2016.61,229.23,1968.65,229.23z M1986.69,182.37
		c-19.58,0-34.98,12.76-40.26,29.92c5.28,0.88,11.22,1.32,17.38,1.32c20.24,0,41.58-5.06,41.58-18.26
		C2005.39,188.53,1998.57,182.37,1986.69,182.37z"
          />
          <path
            className="st2"
            d="M2114.73,188.97h-1.1c-3.3-1.76-7.04-1.98-10.56-1.98c-18.04,0-25.52,20.02-27.94,34.54l-8.36,52.36h-20.46
		l14.08-89.32c0.44-2.86,0.44-5.72,0.44-8.14c0-4.62-0.66-7.26-0.66-7.26h20.68c0,0,0.66,2.42,0.66,7.04c0,2.64-0.22,5.72-0.88,9.24
		h0.22c5.94-10.34,15.62-18.04,27.94-18.04c3.3,0,7.48,0.22,10.34,1.76L2114.73,188.97z"
          />
        </g>
      </svg>
    </OuterDiv>
  )
}

const OuterDiv = styled.div`
  svg {
    height: 46px;
  }
  .st1 {
    fill: #8ce2f9;
  }
  .st2 {
    fill: #fff;
  }
  .st3 {
    fill: #f9bd91;
  }
  .st4 {
    fill: ${(props) => props.theme.headerBackgroundColor};
  }
`

Logo.defaultProps = {
  strokeWidth: 10,
}

export {Logo}
