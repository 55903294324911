import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import {device} from "../helpers/mediaQueries"

const Flex = (props) => <OuterDiv {...props}>{props.children}</OuterDiv>

const OuterDiv = styled.div`
  align-items: ${(props) => props.alignItems};
  display: ${(props) => props.isMobile && "flex"};
  flex-wrap: ${(props) => props.flexWrap};
  justify-content: ${(props) => props.justifyContent};
  @media ${device.desktop} {
    display: ${(props) => props.isDesktop && "flex"};
  }
`

Flex.propTypes = {
  alignItems: PropTypes.string,
  flexWrap: PropTypes.string,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  justifyContent: PropTypes.string,
}

Flex.defaultProps = {
  alignItems: "center",
  flexWrap: "wrap",
  isMobile: true,
  isDesktop: true,
  justifyContent: "flex-start",
}

export default Flex
