import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import {device} from "../helpers/mediaQueries"

const Spacer = (props) => <OuterDiv {...props} />

const OuterDiv = styled.div`
  display: ${(props) => !props.isMobile && "none"};
  height: ${(props) => props.theme.gutterMobile}px;
  @media ${device.desktop} {
    display: ${(props) => !props.isDesktop && "none"};
    height: ${(props) => props.theme.gutterDesktop}px;
  }
`

Spacer.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
}

Spacer.defaultProps = {
  isDesktop: true,
  isMobile: true,
}

export default Spacer
