import PropTypes from "prop-types"
import React from "react"
import Headroom from "react-headroom"
import styled from "styled-components"
import {device} from "../helpers/mediaQueries"
import Navigation from "./Navigation"

const Header = ({currentPath}) => {
  return (
    <Headroom>
      <HeaderStyled>
        <Navigation currentPath={currentPath} />
      </HeaderStyled>
    </Headroom>
  )
}

const HeaderStyled = styled.header`
  background-color: #222733;
  border-bottom: 2px solid white;
  padding: 20px;
  position: relative;
  z-index: 2;
  a {
    color: inherit;
  }
  @media ${device.desktop} {
    height: ${(props) => props.theme.navigationDesktopHeight}px;
    padding: 0;
    text-align: left;
  }
`

Header.propTypes = {
  currentPath: PropTypes.string.isRequired,
}

export default Header
