import styled from "styled-components"
import {device} from "../helpers/mediaQueries"

const Gutter = styled.div`
  ${(props) => (props.useMargin ? "margin" : "padding")}-bottom: ${(props) =>
    props.isMobile && props.bottomPadding ? props.theme.gutterMobile : 0}px;
  ${(props) => (props.useMargin ? "margin" : "padding")}-left: ${(props) =>
    props.isMobile && props.leftPadding ? props.theme.gutterMobile : 0}px;
  ${(props) => (props.useMargin ? "margin" : "padding")}-right: ${(props) =>
    props.isMobile && props.rightPadding ? props.theme.gutterMobile : 0}px;
  ${(props) => (props.useMargin ? "margin" : "padding")}-top: ${(props) =>
    props.isMobile && props.topPadding ? props.theme.gutterMobile : 0}px;
  @media ${device.desktop} {
    ${(props) => (props.useMargin ? "margin" : "padding")}-bottom: ${(props) =>
      props.isDesktop && props.bottomPadding ? props.theme.gutterDesktop : 0}px;
    ${(props) => (props.useMargin ? "margin" : "padding")}-left: ${(props) =>
      props.isDesktop && props.leftPadding ? props.theme.gutterDesktop : 0}px;
    ${(props) => (props.useMargin ? "margin" : "padding")}-right: ${(props) =>
      props.isDesktop && props.rightPadding ? props.theme.gutterDesktop : 0}px;
    ${(props) => (props.useMargin ? "margin" : "padding")}-top: ${(props) =>
      props.isDesktop && props.topPadding ? props.theme.gutterDesktop : 0}px;
  }
`

Gutter.defaultProps = {
  bottomPadding: true,
  isDesktop: true,
  isMobile: true,
  leftPadding: true,
  rightPadding: true,
  topPadding: true,
  useMargin: false,
}

export default Gutter
